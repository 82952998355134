




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { getOwnApps } from '../utils/util';
import Vue from 'vue';
import { COUNTRIES } from '../utils/constants';
import { LSS } from '@core/services';
import { CampaignsService } from '@services/campaigns.service';
import { CustomerTypes, Genders } from '../utils/enums';
import {
  IAudience,
  ICampaign,
  IAdGroup,
  ICampaignReq,
} from '@shared/interfaces';
import XLSX from 'xlsx';

import AddCreativeSet from '../components/campaign/AddCreativeSet.vue';

import { LS_DRAFT_KEY } from '@shared/config';
import {
  GET_CAMPAIGN_DRAFT,
  GET_CAMPAIGN_GROUPS,
  APPS_BY_ORG,
  CHECK_CAMPAIGN_NAME,
  COUNTRIES_FOR_APP,
} from '../utils/apollo-queries';
import { CAMPAIGN_DRAFT, CREATE_CAMPAIGN } from '../utils/apollo-mutations';
import { CampaignCreationSteps } from '../utils/enums';
import { IMutationOptions } from '../utils/interfaces';
import AddKeys from '../components/keywords/AddKeys.vue';
import AddnKeys from '../components/keywords/AddnKeys.vue';
import * as _ from 'lodash';
import { updateCLF } from '../utils/util';
import { APP_METADATA } from 'src/utils/apptweakqueries';
import { KOCHAVA_BUDGET, KOCHAVA_COMPANY } from '@config';

export default Vue.extend({
  components: {
    AddCreativeSet,
    AddKeys,
    AddnKeys,
  },
  data() {
    return {
      selectedStatus:'0',
      selectedStatusChange:'0',
      selectedDeviceCpp:{},
      selectLangDeviceChange:'',
      selectDeviceLang:{},
      selectDeviceName:[],
      selectDeviceDetails:[],
      selectedDeviceScreenSorts:'',
      syncStatus: false,
      locale: 'en',
      searchInput: null,
      search: null,
      timerId: null,
      areMultipleCountries: false,
      fullscreenLoader: null,
      savingCampaignDraft: false,
      savingCountriesRegions: false,
      addedCreativeSetDetails: null,
      targetingKeywordsFile: null,
      negativeKeywordsFile: null,
      expandAdScheduling: false,
      savingCampaign: false,
      addKeywordsData: {},
      addnKeywordsData: {},
      csCountries: [],
      menuDisplays: null,
      ex4: null,
      successDialog: false,
      phones: [
        {
          size: '6.5” Display',
          description: 'Display iPhone XS Max',
        },
        {
          size: '6.1” Display',
          description: 'Display iPhone XR',
        },
        {
          size: '5.8” Display',
          description: 'Display iPhone X, iPhone XS',
        },
      ],
      ipads: [
        {
          size: '12.9” Display',
          description: 'Display iPad Pro 12.9”',
        },
        {
          size: '9.7” Display',
          description:
            'Display iPad Pro 9.7”, iPad (2017), iPad Air 2, iPad Air, iPad (4th generation)',
        },
      ],
      orgId: '',
      currency: '',
      campaign: null,
      adGroup: null,
      loading: false,
      searchedApps: [],
      searchLoc: null,
      selectedApp: null,
      campaginGroups: [],
      drawer: true,
      mini: true,
      step: CampaignCreationSteps.APP_SELECTION,
      previousStep: null,

      switch1: true,
      switch2: false,

      dialog: false,

      e2: 'Texas',
      e3: null,
      e4: null,
      states: [
        { text: 'State 1' },
        { text: 'State 2' },
        { text: 'State 3' },
        { text: 'State 4' },
        { text: 'State 5' },
        { text: 'State 6' },
        { text: 'State 7' },
      ],
      nullDatetime: null,
      datePickerProps: {
        min: new Date().toISOString(),
      },
      datetime: new Date(),
      datetimeString: '2019-01-01 12:00',
      formattedDatetime: '09/01/2019 12:00',
      textFieldProps: {
        appendIcon: 'event',
      },
      dateProps: {
        headerColor: 'red',
      },
      timeProps: {
        useSeconds: true,
        ampmInTitle: true,
      },
      endTimeCheck: false,

      appList: [],
      //start
      valid: true,

      //campaign
      campaignGroupName: '',
      appId: null,
      appDetailsForCreativeSet: null,

      //Countries Regions
      campaignName: null,
      selectedCountries: null,
      appCountries: [],
      Countries: null,
      budget: null,
      dailyCap: '',

      //adgroup
      adGroupName: '',
      selectDevices: '',
      Devices: [
        { text: 'IPAD', src: 'mdi-tablet' },
        { text: 'IPHONE', src: 'mdi-cellphone' },
      ],
      startdate: null,
      enddate: '',
      menu1: false,
      startmodal: false,
      endmodal: false,
      menu2: false,
      defaultBidAmount: '',
      cpaGoal: '',
      daypart: null,
      checkbox: true,
      menu: null,
      date: null,

      //Keywords
      tKeys: [],
      nKeys: [],

      //search match
      autoOpt: false,

      //audience
      ageRange: false,
      ageRangeValue: [18, 35],
      locations: [],

      selectedGender: Genders.ALL,
      selectedCustomerType: CustomerTypes.ALL,
      selectedLocation: '',

      //creative sets
      noCreativeSet: false,
      creativeappassets: null,
      creativeSetName: [],
      csName: '',
      tab: null,
      allImgs: {},
      selectedImgs: [],
      defaultImages: [],
      defaultGenIds: [],
      isAppSearchLoading: false,

      //cpp
      isCppOptionBool: false,
      noCpp: false,
      cpp: [],
      adName: '',
      tempSelectedCpp: '',
      selectedCpp: '',
      selectedCppScreenshot: '',
      tempSelectedCppScreenshot: '',
      selectedCppName: '',
      tempSelectedCppName: '',
      adDialog: false,
      adDialogEdit: false,
      cppError: '',
      sortBy: '',
      cppSortBy: ['A-Z', 'Z-A'],
      cppSearch: '',

      //Validation
      valid1: false,
      // campaignGroupRules: [(v) => !!v || 'Please select campaign group!'],
      //appRules: [(v) => !!v || 'Please select an app!'],

      valid2: false,
      // countryRules: [(v) => !!(v && v.length) || 'Please select country!'],
      // campaignNameRules: [(v) => !!v || 'Campaign name required!'],
      // budgetRules: [
      // (v) => !!v || 'Budget amount is required!',
      // (v) => !!/^[0.1-9]*$/gi.test(v) || 'Please enter a valid budget!',
      // ],

      valid3: false,
      // adgroupNameRules: [(v) => !!v || 'Adgroup name required!'],
      // cptBidRules: [
      // (v) => !!v || 'Default Max CPT Bid is required!',
      // (v) => !!/^[0.1-9]*$/gi.test(v) || 'Please enter a valid CPT Bid!',
      // ],
      // dailyCapRules: [
      // (v) =>
      // !!/^[0.1-9|(^$)]*$/gi.test(v) || 'Please enter a valid daily cap!',
      // ],
      // cpaGoalRules: [
      // (v) =>
      // !!/^[0.1-9|(^$)]*$/gi.test(v) || 'Please enter a valid CPA goal!',
      // ],
      // deviceRules: [(v) => !!(v && v.length) || 'Please select devices!'],
    };
  },

  computed: {
    filteredCppList() {
      return this.cpp.filter((i) => {
        return i.name.toLowerCase().includes(this.cppSearch.toLowerCase());
      });
    },
    c: function (): string {
      return '';
    },
    draftId: {
      cache: false,
      get(): string {
        return localStorage.getItem(LS_DRAFT_KEY) as string;
      },
      set(value: string): void {
        localStorage.setItem(LS_DRAFT_KEY, value);
      },
    },

    campaignGroupRules: {
      cache: false,
      get(): string {
        return [(v) => !!v || this.$t('createCampaignRules.selectCampGrp')];
      },
    },

    appRules: {
      cache: false,
      get(): string {
        return [(v) => !!v || this.$t('createCampaignRules.selectApp')];
      },
    },

    countryRules: {
      get() {
        return [
          (v) =>
            !!(v && v.length) || this.$t('createCampaignRules.selectCountry'),
        ];
      },
    },

    campaignNameRules: {
      get() {
        return [
          (v) => !!v || this.$t('createCampaignRules.campRequired'),
          (v) =>
            (v && v.length <= 199) ||
            this.$t('createCampaignRules.campNameSize'),
        ];
      },
    },
    budgetRules: {
      get() {
        return [
          (v) => !!v || this.$t('createCampaignRules.budgetRequired'), //this.$t('createCampaignRules.budgetRequired')
          (v) =>
            !!/^[0.1-9]*$/gi.test(v) ||
            this.$t('createCampaignRules.validBudget'), // this.$t('createCampaignRules.validBudget')
        ];
      },
    },

    // valid3: false,
    adgroupNameRules: {
      get() {
        return [
          (v) => !!v || this.$t('createCampaignRules.groupRequired'),
          (v) =>
            (v && v.length <= 199) ||
            this.$t('createCampaignRules.adGroupSize'),
        ];
      },
    },
    cptBidRules: {
      get() {
        return [
          (v) => !!v || this.$t('createCampaignRules.defMaxCptRequired'), // this.$t('createCampaignRules.defMaxCptRequired')
          (v) =>
            !!/^[0.1-9]*$/gi.test(v) ||
            this.$t('createCampaignRules.validCptBid'), // this.$t('createCampaignRules.validCptBid')
        ];
      },
    },

    dailyCapRules: {
      get() {
        return [
          (v) =>
            !!/^[0.1-9|(^$)]*$/gi.test(v) ||
            this.$t('createCampaignRules.validDailyCap'), // this.$t('createCampaignRules.validDailyCap')
        ];
      },
    },

    cpaGoalRules: {
      get() {
        return [
          (v) =>
            !!/^[0.1-9|(^$)]*$/gi.test(v) ||
            this.$t('createCampaignRules.validCpaGoal'), // this.$t('createCampaignRules.validCpaGoal')
        ];
      },
    },

    deviceRules: {
      get() {
        return [
          (v) =>
            !!(v && v.length) || this.$t('createCampaignRules.selectDevices'),
        ];
      },
    },

    nKeysheaders: {
      cache: false,
      get() {
        return [
          {
            text: '',
            align: 'start',
            value: 'delete',
          },
          {
            text: this.$t('createCampaign.key'),
            sortable: true,
            value: 'text',
          },
          { text: this.$t('createCampaign.matchType'), value: 'matchType' },
        ];
      },
    },

    tKeysheaders: {
      cache: false,
      get() {
        return [
          {
            text: '',
            align: 'start',
            value: 'delete',
          },
          {
            text: this.$t('createCampaign.key'),
            sortable: true,
            value: 'text',
          },
          { text: this.$t('createCampaign.matchType'), value: 'matchType' },
          {
            text: this.$t('createCampaign.amount') + ' ($)',
            value: 'bidAmount.amount',
          },
          {
            text: this.$t('createCampaign.currency'),
            value: 'bidAmount.currency',
          },
        ];
      },
    },

    //audience
    customerTypes: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createCampaign.allUsers'),
            value: CustomerTypes.ALL,
          },
          {
            text: this.$t('createCampaign.newUsers'),
            value: CustomerTypes.NEW,
          },
          {
            text: this.$t('createCampaign.appUsers'),
            value: CustomerTypes.OTHER_APPS,
          },
          {
            text: this.$t('createCampaign.existingUsers'),
            value: CustomerTypes.EXISITING,
          },
        ];
      },
    },

    gender: {
      cache: false,
      get() {
        return [
          { text: this.$t('createCampaign.all'), value: Genders.ALL },
          { text: this.$t('createCampaign.male'), value: Genders.MALE },
          { text: this.$t('createCampaign.female'), value: Genders.FEMALE },
        ];
      },
    },
    customBorder: function () {
      return this.isCppOptionBool ? 'br-primary' : '';
    },
    defaultBorder: function () {
      return this.isCppOptionBool == false ? 'br-primary' : '';
    },
  },
  apollo: {
    countriesForApp: {
      query: COUNTRIES_FOR_APP,
      variables() {
        return {
          adamId: this.selectedApp.adamId,
        };
      },
      skip() {
        return !this.selectedApp;
      },
      update(data) {
        this.setCountries(data.countriesForApp);
      },
    },
    campaignDraft: {
      query: GET_CAMPAIGN_DRAFT,
      variables() {
        return {
          draftId: this.draftId,
        };
      },
      skip: true,
      async update(data) {
        await this.patchCampaignFormWithDraft(
          data.campaignDraft as ICampaignReq
        );
        this.fullscreenLoader.hide();
      },
    },
    getCampaignGroups: {
      query: GET_CAMPAIGN_GROUPS,
      skip(): boolean {
        return false;
      },
    },
  },
  methods: {
    selectCpp: function (id) {
      return this.tempSelectedCpp == id ? 'br-primary' : '';
    },
    async searchForApps(query) {
      if (!this.orgId) return;

      clearTimeout(this.timerId);

      // delay new call 500ms
      this.timerId = setTimeout(async () => {
        this.isAppSearchLoading = true;
        const result = await this.$apollo.query({
          query: APPS_BY_ORG,
          variables: {
            orgId: this.orgId,
            query: query,
          },
        });

        await this.appListing(result.data.apps);

        this.isAppSearchLoading = false;
      }, 800);
    },
    appListing(data) {
      this.appList = [];

      for (const app of data) {
        // if(LSS.ownApps && LSS.ownApps.length > 0) {
        //   for (let index = 0; index < LSS.ownApps.length; index++) {
        //     const element = LSS.ownApps[index];
        //     if(element.adamId == app.adamId){
        //       this.appList.push({
        //         appName: element.appName,
        //         adamId: app.adamId,
        //         appIcon: element.appIcon,
        //       });
        //     }
        //   }
        // }
        this.$apollo
          .query({
            query: APP_METADATA,
            variables: {
              appId: app.adamId,
              location: 'us',
              language: 'en',
              device: 'iphone',
              startDate: new Date().toISOString().substr(0, 10),
              endDate: new Date().toISOString().substr(0, 10),
            },
          })
          .then((data) => {
            this.appList.push({
              appName: data.data.getAppMetadata.content.title,
              adamId: app.adamId,
              appIcon: data.data.getAppMetadata.content.icon,
            });
            if (
              data.data.getAppMetadata == null ||
              data.data.getAppMetadata == undefined
            ) {
              return;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    closeSuccessDialog() {
      this.successDialog = false;
      LSS.clearOther();
      this.$router.push('/ads-manager');
    },

    async checkCampaignName() {
      const result = await this.$apollo.query({
        query: CHECK_CAMPAIGN_NAME,
        variables: {
          name: this.campaignName,
        },
      });

      if (result.data.checkCampaignName.doesCampaignExist) {
        this.campaignNameRules.push('Campaign name already exists!');
      } else {
        this.campaignNameRules.splice(1);
      }

      // making validation rules run manually
      const val = this.campaignName;
      this.campaignName = '';
      setTimeout(() => {
        this.campaignName = val;
      }, 0);
    },

    stepChanged() {
      this.syncDataWithLs();
    },

    async patchCampaignFormWithDraft(campaignDraft: ICampaignReq): void {
      this.orgId = campaignDraft.campaign.orgId;
      this.selectedApp = { adamId: campaignDraft.campaign.adamId };
      this.selectedCountries = campaignDraft.campaign.countriesOrRegions;
      this.budget = campaignDraft.campaign.budgetAmount.amount;
      this.currency = campaignDraft.campaign.budgetAmount.currency;
      this.dailyCap = campaignDraft.campaign.dailyBudgetAmount?.amount || '';
      this.campaignName = campaignDraft.campaign.name;
      if (campaignDraft.adGroup) {
        this.adGroupName = campaignDraft.adGroup?.name;
        this.defaultBidAmount = campaignDraft.adGroup?.defaultBidAmount?.amount;
        this.cpaGoal = campaignDraft.adGroup?.cpaGoal?.amount || '';
        this.startdate = new Date(campaignDraft.adGroup?.startTime);
        if (campaignDraft.adGroup?.endTime) {
          this.endTimeCheck = true;
          this.enddate = new Date(campaignDraft.adGroup?.endTime);
        }
        this.selectDevices =
          campaignDraft.adGroup?.targetingDimensions?.deviceClass?.included;
      }
      this.autoOpt = campaignDraft.adGroup?.automatedKeywordsOptIn;

      if (campaignDraft.targetKeywords)
        this.tKeys = campaignDraft.targetKeywords;
      if (campaignDraft.negativeKeywords)
        this.nKeys = campaignDraft.negativeKeywords;

      if (campaignDraft.adGroup?.targetingDimensions.age) {
        this.ageRange = true;
        this.ageRangeValue = [
          campaignDraft.adGroup?.targetingDimensions.age.included[0].minAge,
          campaignDraft.adGroup?.targetingDimensions.age.included[0].maxAge,
        ];
      }

      if (campaignDraft.adGroup?.targetingDimensions.gender) {
        this.selectedGender =
          campaignDraft.adGroup?.targetingDimensions.gender.included[0];
      }

      if (campaignDraft.creativeSet) {
        this.addedCreativeSetDetails = campaignDraft.creativeSet;
      }
      if (campaignDraft.cpp) {
        this.selectedCpp = campaignDraft.cpp;
        this.adName = campaignDraft.adName;
        this.isCppOptionBool = true;
      }
      await this.moveToStep(campaignDraft.step);
    },

    async moveToStep(step: number): void {
      this.step = step;
      this.setProperties(true);
      await this.saveCountriesRegions(true);

      if (step < CampaignCreationSteps.SEARCH_MATCH) return;

      this.saveAdGroupObject(true);

      if (step < CampaignCreationSteps.KEYWORDS) return;

      this.saveAutoOptIn(true);

      if (step < CampaignCreationSteps.AUDIENCE) return;

      this.saveKeywords(true);

      if (step < CampaignCreationSteps.CREATIVE_SETS) return;

      this.appendAudience(true);
    },

    syncDataWithLs() {
      // watching 'step' property to get 'previousStep' in watchers
      switch (this.previousStep) {
        case CampaignCreationSteps.APP_SELECTION: {
          this.setProperties(true);
          break;
        }
        case CampaignCreationSteps.COUNTRIES_REGIONS: {
          this.saveCountriesRegions(true);
          break;
        }
        case CampaignCreationSteps.AD_GROUP: {
          this.saveAdGroupObject(true);
          break;
        }
        case CampaignCreationSteps.SEARCH_MATCH: {
          this.saveAutoOptIn(true);
          break;
        }
        case CampaignCreationSteps.KEYWORDS: {
          this.saveKeywords(true);
          break;
        }
        case CampaignCreationSteps.AUDIENCE: {
          this.appendAudience(true);
          break;
        }
      }
    },

    async createCampaign() {
      if (this.isCppOptionBool) {
        if (!this.selectedCpp) {
          this.cppError = 'Note : You must create an ad to continue.';
          return;
        }
      } else {
        this.selectedCpp = '';
        this.adName = '';
      }
      this.savingCampaign = true;
      const campaignRequest: ICampaignReq = {
        campaign: this.campaign,
        adGroup: this.adGroup,
        creativeSet: this.addedCreativeSetDetails,
        targetKeywords: this.tKeys,
        negativeKeywords: this.nKeys,
        draftId: this.draftId,
        cpp: this.selectedCpp,
        adName: this.adName,
      };
      try {
        const result = await this.runMutation({
          mutationQuery: CREATE_CAMPAIGN,
          variables: {
            orgId: this.orgId,
            campaignRequest,
          },
        });
        console.log('createCampaignResponse in CreateCampaign :',result);
        this.savingCampaign = false;

        if (result.data.createCampaign.searchAdsErrors) {
          return this.$notify.error(result.data.createCampaign.searchAdsErrors);
        }
        updateCLF('createCampaign', this);
        await getOwnApps('create-campaign', this);
        this.successDialog = true;
      } catch (error) {
        this.savingCampaign = false;
        console.log(error);
      }
    },
    async saveAsDraft() {
      this.savingCampaignDraft = true;
      const campaignDraft: ICampaignReq = {
        campaign: this.campaign,
        adGroup: this.adGroup,
        targetKeywords: this.tKeys,
        negativeKeywords: this.nKeys,
        creativeSet: this.addedCreativeSetDetails,
        cpp: this.selectedCpp,
        adName: this.adName,
        step: +this.step,
      };

      try {
        await this.runMutation({
          mutationQuery: CAMPAIGN_DRAFT,
          variables: { campaignDraft, draftId: this.draftId },
        });

        this.$notify.success(this.$t('successMsg.campaignSavedMsg'));
        this.savingCampaignDraft = false;
        this.$router.push('ads-manager');
      } catch {
        this.$notify.error(this.$t('successMsg.campaignSaveFailMsg'));
        this.savingCampaignDraft = false;
      }
    },
    async searchLocations(locationName: string, countrycode: string) {
      this.loading = true;
      const locations = this.locations.filter(e=>this.selectedLocation.includes(e.id))
      this.locations = locations
      this.locations.push(...await CampaignsService.searchedLocations(
        this.orgId,
        locationName,
        countrycode
      ));
      this.loading = false;
    },
    setProperties(syncing = false) {
      if (!syncing) this.step = CampaignCreationSteps.COUNTRIES_REGIONS;
    },
    async saveCountriesRegions(syncing = false) {
      this.savingCountriesRegions = true;
      const campaign: ICampaign = {
        countriesOrRegions: this.selectedCountries,
        name: this.campaignName,
        budgetAmount: {
          currency: this.currency,
          amount: this.budget,
        },
        ...(this.dailyCap && {
          dailyBudgetAmount: {
            currency: this.currency,
            amount: this.dailyCap,
          },
        }),
        orgId: parseInt(this.orgId),
        adChannelType: 'SEARCH',
        supplySources: ['APPSTORE_SEARCH_RESULTS'],
        adamId: parseInt(this.selectedApp.adamId),
        // TODO v4
        billingEvent: 'TAPS',
      };

      this.campaign = campaign;
      /* try {
        if (this.selectedApp?.adamId && this.selectedCountries[0]) {
          const creativeappassets = await CampaignsService.creativeappassets(
            this.orgId,
            this.selectedApp.adamId,
            this.selectedCountries
          );
          this.csName = 'cs-' + Date.now();
          const enUs = creativeappassets.creativeSetDetails['en-US'];
          this.assignImages(enUs.appPreviewDeviceWithAssets);
        }
      } catch {
        this.noCreativeSet = true;
      } */
      if (!syncing) this.step = CampaignCreationSteps.AD_GROUP;
      this.savingCountriesRegions = false;
    },
    assignImages(object) {
      this.allImgs['tabs'] = []; //add tabs property to allImgs object, this will contain tab names
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
          this.allImgs['tabs'].push(key); // for each key, push it into allImgs['tabs']
          this.allImgs[key] = []; //add key itself as a property
          const imgs = object[key].screenshots; //get screenshot details for each key
          imgs.forEach((element) => {
            const temp = {
              //create object with url and genId and push it into allImgs
              assetURL: element.assetURL,
              assetGenId: element.assetGenId,
            };
            this.allImgs[key].push(temp);
          });
          this.defaultImages.push(imgs[0].assetURL);
          this.defaultGenIds.push(imgs[0].assetGenId);
        }
      }
    },
    openAddCreativeSet() {
      this.appDetailsForCreativeSet = {
        orgId: this.orgId,
        adamId: this.selectedApp.adamId,
        countries: this.selectedCountries,
        now: Date.now(),
      };
    },
    async saveAdGroupObject(syncing = false) {
 
      this.campaign = {
        ...this.campaign,
        startTime: this.startdate,
        ...(this.enddate && {
          endTime: this.enddate,
        }),
      };

      const adGroup: IAdGroup = {
        orgId: parseInt(this.orgId),
        status: 'ENABLED',
        name: this.adGroupName,
        // TODO v4
        pricingModel: 'CPC',
        defaultBidAmount: {
          currency: this.currency,
          amount: this.defaultBidAmount,
        },
        ...(this.cpaGoal && {
          cpaGoal: {
            currency: this.currency,
            amount: this.cpaGoal,
          },
        }),
        startTime: this.startdate,
        ...(this.enddate && { endTime: this.enddate }),
        targetingDimensions: {
          deviceClass: {
            included: this.selectDevices,
          },
          country: {
            included: [],
          },
          ...(this.daypart && {
            daypart: {
              userTime: {
                included: this.daypart.split(','),
              },
            },
          }),
        },
      };
      this.adGroup = adGroup;
      try {
        if (
          this.selectedApp?.adamId &&
          this.adGroup.targetingDimensions?.deviceClass?.included?.length
        ) {
          const cpp = await CampaignsService.cpp(
            this.selectedApp.adamId,
            this.orgId,
            this.adGroup.targetingDimensions.deviceClass.included,
            this.selectedCountries
          );
          // this.csName = 'cs-' + Date.now();
          // const enUs = creativeappassets.creativeSetDetails['en-US'];
          // this.assignImages(enUs.appPreviewDeviceWithAssets);

          this.cpp = cpp.cpp;
         
          if (Array.isArray(this.cpp) && this.cpp.length > 0) {
            this.noCpp = false;
          } else {
            this.noCpp = true;
          }
          if (!this.draftId) {
            this.resetSelectedCpp();
          } else {
            if (this.selectedCpp) {
              const temp = this.cpp.filter((item) => {
                return item?.id == this.selectedCpp;
              });
              this.tempSelectedCpp = this.selectedCpp;
              this.selectedCppScreenshot = temp[0].screenshots
                ? temp[0].screenshots
                : '';
              this.tempSelectedCppScreenshot = this.selectedCppScreenshot;
              this.selectedCppName = temp[0].name ? temp[0].name : '';
              this.tempSelectedCppName = this.selectedCppName;
            }
          }
        }
      } catch {
        this.noCpp = true;
        this.resetSelectedCpp();
      }
      if (!syncing) this.step = CampaignCreationSteps.SEARCH_MATCH;
    },
    async saveAutoOptIn(syncing = false) {
      this.adGroup = {
        ...this.adGroup,
        automatedKeywordsOptIn: this.autoOpt,
      };
      if (!syncing) this.step = CampaignCreationSteps.KEYWORDS;
    },
    tKeysChange: async function (e) {
      var reader = new FileReader();
      reader.onload = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        let sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];
        const j = XLSX.utils.sheet_to_json(worksheet);

        let result = j.map((elem) => ({
          text: elem.name,
          matchType: elem.match || 'EXACT',
          bidAmount: {
            currency: elem.currency || 'USD',
            amount: elem.amount.toString(),
          },
        }));
        if (
          result.some(
            (e) =>
              e.text === undefined ||
              e.matchType === undefined ||
              e.bidAmount.amount === undefined ||
              e.bidAmount.currency === undefined
          )
        ) {
          this.$notify.error(
            'Enter valid File. You can check the example sheet available'
          );
          return;
        }
        //console.log(result);
        result.forEach((element) => {
          if (!this.tKeys.find((kw) => kw.text === element.text)) {
            this.tKeys.push(element);
          }
        });
        this.targetingKeywordsFile = null;
      };
      reader.readAsArrayBuffer(e);
    },
    nKeysChange: function (e) {
      var reader = new FileReader();
      reader.onload = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        let sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];
        const j = XLSX.utils.sheet_to_json(worksheet);

        let result = j.map((elem) => ({
          text: elem.name,
          matchType: elem.match || 'EXACT',
        }));
        if (
          result.some((e) => e.text === undefined || e.matchType === undefined)
        ) {
          this.$notify.error(
            'Enter valid File. You can check the example sheet available'
          );
          return;
        }
        result.forEach((element) => {
          if (!this.nKeys.find((kw) => kw.text === element.text)) {
            this.nKeys.push(element);
          }
        });
        this.negativeKeywordsFile = null;
      };
      reader.readAsArrayBuffer(e);
    },
    async saveKeywords(syncing = false) {
      if (!syncing) this.step = CampaignCreationSteps.AUDIENCE;
    },
    async appendAudience(syncing = false) {
      const adamId = this.selectedApp.adamId;
      let allOwnedApps = this.appList.map((app) => app.adamId);
      allOwnedApps  = allOwnedApps.filter((value, index) => allOwnedApps.indexOf(value) === index)
      const audience: IAudience = {
        // getting audience object set by adgroup screen
        ...this.adGroup.targetingDimensions,
        // age criteria
        ...(this.ageRange && {
          age: {
            included: [
              { minAge: this.ageRangeValue[0], maxAge: this.ageRangeValue[1] },
            ],
          },
        }),
        // gender criteria
        ...(this.selectedGender && {
          gender: {
            included: [this.selectedGender],
          },
        }),
        // App downloader criteria (customer type drop-down)
        ...(this.selectedCustomerType === CustomerTypes.NEW && {
          appDownloaders: {
            excluded: [adamId.toString()],
          },
        }),
        ...(this.selectedCustomerType === CustomerTypes.EXISITING && {
          appDownloaders: {
            included: [adamId.toString()],
          },
        }),
        ...(this.selectedCustomerType === CustomerTypes.OTHER_APPS && {
          appDownloaders: {
            included: [...allOwnedApps.map((adamId) => adamId.toString())],
          },
        }),
        ...(this.selectedLocation && {
          locality: { included: this.selectedLocation },
        }),
      };
      this.adGroup = {
        // TODO v4
        pricingModel: 'CPC',
        ...(this.adGroup.defaultBidAmount && {
          defaultBidAmount: this.adGroup.defaultBidAmount,
        }),
        ...(this.adGroup.cpaGoal && { cpaGoal: this.adGroup.cpaGoal }),
        ...(this.adGroup.startTime && { startTime: this.adGroup.startTime }),
        ...(this.adGroup.endTime && { endTime: this.adGroup.endTime }),
        ...(this.adGroup.status && { status: this.adGroup.status }),
        ...(this.adGroup.orgId && { orgId: this.adGroup.orgId }),
        ...(this.adGroup.automatedKeywordsOptIn && {
          automatedKeywordsOptIn: this.adGroup.automatedKeywordsOptIn,
        }),
        ...(this.adGroup.name && { name: this.adGroup.name }),
        targetingDimensions: audience,
      };

      if (!syncing) this.step = CampaignCreationSteps.CREATIVE_SETS;
    },
    creativeSetObject({ creativeSet }) {
      this.addedCreativeSetDetails = creativeSet;
    },
    async runMutation(mutationOptions: IMutationOptions) {
      return await this.$apollo.mutate({
        mutation: mutationOptions.mutationQuery,
        variables: mutationOptions.variables,
      });
    },
    addTargetKeywords() {
      this.addKeywordsData = {
        appId: this.selectedApp.adamId,
        appName: this.selectedApp.appName,
        currency: this.currency,
        countryCode: this.selectedCountries[0],
        campaignBudget: this.budget,
        now: Date.now(),
      };
    },
    addNegativeKeywords() {
      this.addnKeywordsData = {
        now: Date.now(),
      };
    },
    addTargetKeysCancelClicked() {
      console.log('addKeysCancelClicked');
    },
    addTargetKeysSaveClicked(event) {
      for (let index = 0; index < event.length; index++) {
        const element = event[index];
        this.tKeys.push(element);
      }
    },
    clearTKeys() {
      this.tKeys = [];
      this.targetingKeywordsFile = null;
    },
    addnKeysSaveClicked(event) {
      for (let index = 0; index < event.length; index++) {
        const element = event[index];
        this.nKeys.push(element);
      }
    },
    clearNKeys() {
      this.nKeys = [];
      this.negativeKeywordsFile = null;
    },
    addnKeysCancelClicked() {
      console.log('addnKeysCancelClicked');
    },
    deleteNkeyFromTable(item) {
      //console.log('deleteNkeyFromTable',item);
      this.nKeys = this.nKeys.filter((kw) => kw !== item);
    },
    deleteTkeyFromTable(item) {
      //console.log('deleteTkeyFromTable',item);
      this.tKeys = this.tKeys.filter((kw) => kw !== item);
    },

    setCountries(data) {
      const Countries = data[0].countryOrRegionCodes;
      for (const countryCode of Countries) {
        for (const country of this.Countries) {
          if (country.Code == countryCode) {
            this.appCountries.push(country);
          }
        }
      }
    },
    changeLang(e,selectedIndex){
        let DeviceName=[];
        let DeviceDetails=[];
        let changeLanguage=this.selectedDeviceCpp[selectedIndex]
        DeviceDetails=Object.values(changeLanguage.appPreviewDeviceWithAssets)
        DeviceName=Object.keys(changeLanguage.appPreviewDeviceWithAssets)   
        this.selectDeviceName=DeviceName;
        this.selectDeviceDetails=DeviceDetails;
        this.selectedDeviceScreenSorts=DeviceDetails[0]
        this.selectedStatus='0';
    },
    changeDevice(e,selectedIndex){
       this.selectedDeviceScreenSorts=this.selectDeviceDetails[selectedIndex];
    },
    setCppOptions(e) {
      let DeviceName=[];
      let DeviceDetails=[];
      let DeviceLang=[];
     const dropDownData= e.locale.map(element=>{
     DeviceLang.push(element.languageCode)
        DeviceDetails=Object.values(element.appPreviewDeviceWithAssets)
        DeviceName=Object.keys(element.appPreviewDeviceWithAssets)   

      })
      this.selectedDeviceCpp=e.locale;
      this.selectDeviceLang=DeviceLang;
      this.selectDeviceName=DeviceName;
      this.selectDeviceDetails=DeviceDetails;
      this.tempSelectedCpp = e.id;
      this.tempSelectedCppScreenshot = e.screenshots;
      this.tempSelectedCppName = e.name;
      this.selectedDeviceScreenSorts=DeviceDetails[0]
      this.selectedStatusChange='0';
      this.selectedStatus='0';

    },
    resetSelectedCpp() {
      this.selectedCpp = '';
    },
    isCppOption(flag) {
      this.isCppOptionBool = flag;
    },
    closeAdDialog(state, type) {
      if (type == 'new') {
        if (state == 'close') {
          this.tempSelectedCpp = '';
          this.selectedCpp = '';
          this.selectedCppName = '';
          this.selectedCppScreenshot = '';
          this.tempSelectedCppName = '';
          this.tempSelectedCppScreenshot = '';
          this.adDialog = false;
        } else {
          this.adDialog = false;
          this.selectedCpp = this.tempSelectedCpp;
          this.selectedCppName = this.tempSelectedCppName;
          this.selectedCppScreenshot = this.tempSelectedCppScreenshot;
        }
      } else {
        if (state == 'close') {
          this.tempSelectedCpp = '';
          this.selectedCpp = '';
          this.selectedCppName = '';
          this.selectedCppScreenshot = '';
          this.tempSelectedCppName = '';
          this.tempSelectedCppScreenshot = '';
          this.adDialogEdit = false;
        } else {
          this.adDialogEdit = false;
          this.selectedCpp = this.tempSelectedCpp;
          this.selectedCppName = this.tempSelectedCppName;
          this.selectedCppScreenshot = this.tempSelectedCppScreenshot;
        }
      }
    },
  },
  watch: {
    sortBy: function (val, oldVal) {
      if (val == 'A-Z') {
        this.filteredCppList.sort((a, b) => {
          let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      } else {
        this.filteredCppList.sort((a, b) => {
          let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        this.filteredCppList.reverse();
      }
    },

    search(query) {
      if (query != this.selectedApp?.appName) this.searchForApps(query);
    },
    selectedCountries(value) {
      /* value && value.length > 1
        ? ((this.areMultipleCountries = true), (this.selectedLocation = null))
        : ((this.areMultipleCountries = false),
          (this.locations = []),
          (this.selectedLocation = null)); */

      if (value && value.length > 1) {
        this.areMultipleCountries = true;
        this.selectedLocation = null;
      } else {
        this.areMultipleCountries = false;
        this.locations = [];
        this.selectedLocation = null;
      }
    },
    orgId(value) {
      this.searchForApps('');
      // handling currency as per the org
      if (value)
        this.currency = this.getCampaignGroups.find(
          (group) => group.orgId === value
        ).currency;
      if (!this.draftId) this.selectedApp = null;
    },
    searchLoc(query: string): void {
      query &&
        query !== this.selectedLocation &&
        this.selectedCountries.length === 1 &&
        query.length > 2 &&
        this.searchLocations(query, this.selectedCountries[0]);
    },

    step(current: number, previous: number): void {
      this.previousStep = Number(previous);
    },

    budget(amount) {
      this.budgetRules.splice(2)
      if(LSS.company == KOCHAVA_COMPANY && amount > KOCHAVA_BUDGET) {
        this.budgetRules.push('A maximum campaign budget of $1 is allowed.')
      }
      else {
        this.budgetRules.splice(2)
      }
    }
  },
  async created() {
    this.Countries = COUNTRIES;

    if (this.draftId) {
      this.$apollo.queries.campaignDraft.skip = false;
      this.fullscreenLoader = this.$loading.show();
    }

    window.addEventListener('beforeunload', () => {
      LSS.clearOther();
    });
  },
  mounted() {
    this.syncStatus = LSS.activeSync
    Vue.prototype.$sckt.on("syncprogress:event", () => {
      this.syncStatus = LSS.activeSync
    });
    this.locale = localStorage.getItem('locale') || 'en'
    this.$root.$on('locale-changed', (locale) => {
      this.locale = locale.localeCode
      //this.$refs.formStep1.resetValidation()
      this.$refs.formStep1.validate();
      this.$refs.formStep2.validate();
      this.$refs.formStep3.validate();
      this.$refs.formStep4.validate();
    });
  },
});
