<template flex>
  <div class="dashboard">
    <v-app>
      <!-- Sizes your content based upon application components -->
      <div class="inner-container  only-heading-part">
        <!-- Provides the application the proper gutter -->
         <!-- <div  class="top-page-header page-header-with-banner">
          <div class="head-title top-space">
            <h1>{{ $t('createCampaign.createCamp') }}</h1>
          </div>
        </div> -->

        <v-container fluid class="v-main-content only-simple-content">
          <!-- If using vue-router -->
          <template fluid>
            <v-card elevation="3" class="createCampaignBox pa-4 rounded-lg">
              <!-- Tabs Open -->
              <v-row>
                <!-- <v-col cols="12" sm="6">
                  <br />
                  <h2 class="text-h6 font-weight-medium mb-3">
                    {{ $t('createCampaign.h2') }}
                  </h2>
                  <p class="text-subtitle-2 mb-3">
                   {{ $t('createCampaign.para') }}
                  </p>
                </v-col> -->

                <v-col cols="12" sm="12">
                  <v-card flat>
                    <v-card-text class="px-0 py-0">
                      <v-form class="px-3">
                        <div class="sub-heading-with-btn mb-0 ">
                        <h3 class=" black-text">
                         {{ $t('createCampaign.select') }}
                        </h3>
                         </div>
                        <v-radio-group v-model="radioGroup" class="mt-0">
                          <v-row class="mt-0">
                            <v-col cols="12" sm="6" lg="6" md="6" xl="3" class="py-0">
                              <!-- bg-hover  -->
                              <div :class="radioGroup==0?'camp-upload-file bg-active ':'camp-upload-file '"  > 
                                <v-label block class="pb-4"></v-label> 
                                 <v-radio :value="0">
                                  <template v-slot:label>
                                    <div>
                                      <strong class="d-block mb-1 ">{{ $t('createCampaign.searchResults') }} </strong>
                                      <div class="text-subtitle-2">
                                        {{ $t('createCampaign.srData') }}
                                      </div>
                                    </div>
                                 <!-- <v-img
                                    src="../assets/images/result-tab.png"
                                  ></v-img> -->
                                   </template>
                                </v-radio>
                              </div>
                            </v-col>
                             <v-col cols="12" sm="6" lg="6" md="6" xl="3" class="py-0">
                              <div :class="radioGroup==1?'camp-upload-file bg-active':'camp-upload-file '"  >
                                <v-label block class="pb-4"></v-label>
                                <v-radio :value="1">
                                  <template v-slot:label>
                                    <div>
                                      <strong  class="d-block mb-1 " >{{ $t('createCampaign.searchTab') }} </strong>
                                      <div class="text-subtitle-2">
                                        {{ $t('createCampaign.stData') }}
                                      </div>
                                    </div>
                                     <!-- <v-img
                                    src="../assets/images/search-tab.png"
                                  ></v-img> -->
                                   </template>
                                </v-radio>
                              </div>
                            </v-col>
                             <v-col cols="12" sm="6" lg="6" md="6" xl="3" class="py-0">
                              <div :class="radioGroup==2?'camp-upload-file bg-active':'camp-upload-file'" >
                                <v-label block class="pb-4"></v-label>
                                <v-radio :value="2">
                                  <template v-slot:label>
                                    <div>
                                      <strong  class="d-block mb-1 ">Today Tab </strong>
                                      <div class="text-subtitle-2">
                                        Reach users when they first come to the App Store to 
                                        discover apps. Your ad will appear prominently on the front page.

                                      </div>
                                    </div>
                                     <!-- <v-img
                                    src="../assets/images/today-tab.png"
                                  ></v-img> -->
                                   </template>
                                </v-radio>
                              </div>
                            </v-col>
                             <v-col cols="12" sm="6" lg="6" md="6" xl="3" class="py-0">
                              <div :class="radioGroup==3?'camp-upload-file bg-active':'camp-upload-file'" >
                                <v-label block class="pb-4"></v-label>
                                <v-radio :value="3">
                                  <template v-slot:label>
                                    <div>
                                      <strong  class="d-block mb-1 ">Product Pages </strong>
                                      <div class="text-subtitle-2">
                                          Reach users when they are browsing apps. 
                                          Your ad will appear at the top. You might also like to list to users who have scrolled to the bottom of relevant product pages.
                                      </div>
                                    </div>
                                     <!-- <v-img
                                    src="../assets/images/product-tab.png"
                                  ></v-img> -->
                                   </template>
                                </v-radio>
                              </div>
                            </v-col>
                          </v-row>

                       <!--   <v-row class="mt-0">
                            <v-col cols="12" sm="12">
                              <div class="camp-upload-file">
                                <v-label block class="pb-4"></v-label>
                                <v-radio :value="1">
                                  <template v-slot:label>
                                    <div>
                                      <strong>{{ $t('createCampaign.searchTab') }} </strong>
                                      <div class="text-subtitle-2">
                                        {{ $t('createCampaign.stData') }}
                                      </div>
                                    </div>
                                  </template>
                                </v-radio>
                              </div>
                            </v-col>
                          </v-row>-->
                        </v-radio-group>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              
              <v-row class="mb-2">
              <v-col cols="12" sm="12" class="text-right pt-0">
                <v-btn color="disable" outlined class="mr-3 w-xsm-100 mb-2" @click="back()"  style="width:120px;" >
                {{ $t('createCampaign.cancel') }}</v-btn >
                <v-btn color="primary" elevation="0" class="mr-3 w-xsm-100 mb-2" @click=" selectNextStep() "  style="width:120px;" >
                {{ $t('createCampaign.continue') }}
                </v-btn  >
              
              </v-col>
            </v-row>

            </v-card>

            <!-- Tabs Open -->
          

          </template>
        </v-container>
      </div>
    </v-app>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
     components: {
    
  },
  name: 'CampaginView',
  data() {
    return {
      radioGroup: 0,
       ProductPageMetadata: {},
    };
  },
  methods: {

     selectNextStep() {
      this.$emit('emitNextStep', this.radioGroup);
    
    // if (this.radioGroup == 3) {
      //  }
    },

    selectCampaign() {
      if (this.radioGroup == 0) {
        return this.$router.push('/create-campaign');
      } else if (this.radioGroup == 1) {
        return this.$router.push('/searchtab-campaign');
      } else if (this.radioGroup == 2) {
        return this.$router.push('/todaystab-campaign');
      } else if (this.radioGroup == 3) {
        return this.$router.push('/productpage-campaign');
      }
    },

    back() {
      return this.$router.push('/ads-manager');
    },
  },
});
</script>
