var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container  only-heading-part" },
          [
            _c(
              "v-container",
              {
                staticClass: "v-main-content only-simple-content",
                attrs: { fluid: "" },
              },
              [
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "createCampaignBox pa-4 rounded-lg",
                      attrs: { elevation: "3" },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "px-0 py-0" },
                                    [
                                      _c(
                                        "v-form",
                                        { staticClass: "px-3" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "sub-heading-with-btn mb-0 ",
                                            },
                                            [
                                              _c(
                                                "h3",
                                                { staticClass: " black-text" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "createCampaign.select"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mt-0",
                                              model: {
                                                value: _vm.radioGroup,
                                                callback: function ($$v) {
                                                  _vm.radioGroup = $$v
                                                },
                                                expression: "radioGroup",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "mt-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6",
                                                        lg: "6",
                                                        md: "6",
                                                        xl: "3",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          class:
                                                            _vm.radioGroup == 0
                                                              ? "camp-upload-file bg-active "
                                                              : "camp-upload-file ",
                                                        },
                                                        [
                                                          _c("v-label", {
                                                            staticClass: "pb-4",
                                                            attrs: {
                                                              block: "",
                                                            },
                                                          }),
                                                          _c("v-radio", {
                                                            attrs: { value: 0 },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "label",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "strong",
                                                                            {
                                                                              staticClass:
                                                                                "d-block mb-1 ",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createCampaign.searchResults"
                                                                                  )
                                                                                ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "text-subtitle-2",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "createCampaign.srData"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ]
                                                            ),
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6",
                                                        lg: "6",
                                                        md: "6",
                                                        xl: "3",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          class:
                                                            _vm.radioGroup == 1
                                                              ? "camp-upload-file bg-active"
                                                              : "camp-upload-file ",
                                                        },
                                                        [
                                                          _c("v-label", {
                                                            staticClass: "pb-4",
                                                            attrs: {
                                                              block: "",
                                                            },
                                                          }),
                                                          _c("v-radio", {
                                                            attrs: { value: 1 },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "label",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "strong",
                                                                            {
                                                                              staticClass:
                                                                                "d-block mb-1 ",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createCampaign.searchTab"
                                                                                  )
                                                                                ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "text-subtitle-2",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "createCampaign.stData"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ]
                                                            ),
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6",
                                                        lg: "6",
                                                        md: "6",
                                                        xl: "3",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          class:
                                                            _vm.radioGroup == 2
                                                              ? "camp-upload-file bg-active"
                                                              : "camp-upload-file",
                                                        },
                                                        [
                                                          _c("v-label", {
                                                            staticClass: "pb-4",
                                                            attrs: {
                                                              block: "",
                                                            },
                                                          }),
                                                          _c("v-radio", {
                                                            attrs: { value: 2 },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "label",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "strong",
                                                                            {
                                                                              staticClass:
                                                                                "d-block mb-1 ",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Today Tab "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "text-subtitle-2",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Reach users when they first come to the App Store to discover apps. Your ad will appear prominently on the front page. "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ]
                                                            ),
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "6",
                                                        lg: "6",
                                                        md: "6",
                                                        xl: "3",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          class:
                                                            _vm.radioGroup == 3
                                                              ? "camp-upload-file bg-active"
                                                              : "camp-upload-file",
                                                        },
                                                        [
                                                          _c("v-label", {
                                                            staticClass: "pb-4",
                                                            attrs: {
                                                              block: "",
                                                            },
                                                          }),
                                                          _c("v-radio", {
                                                            attrs: { value: 3 },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "label",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "strong",
                                                                            {
                                                                              staticClass:
                                                                                "d-block mb-1 ",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Product Pages "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "text-subtitle-2",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Reach users when they are browsing apps. Your ad will appear at the top. You might also like to list to users who have scrolled to the bottom of relevant product pages. "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ]
                                                            ),
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right pt-0",
                              attrs: { cols: "12", sm: "12" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-3 w-xsm-100 mb-2",
                                  staticStyle: { width: "120px" },
                                  attrs: { color: "disable", outlined: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.back()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("createCampaign.cancel"))
                                  ),
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-3 w-xsm-100 mb-2",
                                  staticStyle: { width: "120px" },
                                  attrs: { color: "primary", elevation: "0" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectNextStep()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("createCampaign.continue")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }